@import '../../constants/colors';

.login {
  padding: 50px 0;

  form {
    margin: 0 auto;
    max-width: 350px;
    border-radius: 20px;
  }

  .form-control {
    border-radius: 20px;
  }

  form button {
    width: 40%;
    margin: auto;
    margin-top: 40px;
    border-radius: 20px;
    color: white !important;
    background-color: $oculo-green !important;
    border-color: $oculo-green !important;
  }

  .input-group-text {
    border-radius: 1rem 0rem 0rem 1rem !important;
  }
}
