@import '../../constants/colors';

.main-header {
  height: 87px;
  padding-left: 0;
  padding-right: 50px;

  .navbar-brand {
    width: 260px;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .logo-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;

    .name {
      text-transform: uppercase;
      letter-spacing: 0.325em;
      font-size: 1.25em;
    }

    .description {
      font-size: 0.94em;
    }
  }

  .header-logo {
    width: 75px;
    height: auto;
  }

  .header-content {
    flex: 1;
    gap: 20px;
  }
}

@media (max-width: 767.98px) {
  .main-header {
    height: 57px;
    padding: 0 25px;
    justify-content: space-between;

    .logo-text {
      font-size: 0.7rem;
    }

    .navbar-brand {
      width: auto;
      gap: 5px;
    }

    .header-logo {
      width: 49px;
      height: auto;
    }

    .navbar-toggler {
      background-color: $plus-light-gray;
      border: none;
      border-radius: 4px;
      width: 40px;
      height: 33px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .navbar-toggler-icon {
      width: 20px;
      height: 20px;
    }

    .search-button {
      color: $plus-light-gray;
      padding: 0.375rem 0.75rem;
      background-color: $oculo-blue;
      border: none;
      border-radius: 20px;
      font-size: 1rem;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .search-button:active {
      background-color: $oculo-red;
    }

    .mobile-search-wrapper {
      position: absolute;
      top: -100%;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $oculo-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      transition-property: top;
      transition-duration: 0.2s;
    }

    .mobile-search-wrapper.open {
      top: 0;
    }

    .mobile-search {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: 0 15px 0 30px;
      position: relative;
    }
  }
  .mobile-search-wrapper .close-search-button {
    margin-right: 5%;
    background-color: white;
    color: $oculo-blue;
    border: none;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }
}
