@import '../../constants/colors';

.action-buttons {
  display: flex;
  gap: 25px;

  .cancel-button {
    background-color: $oculo-red;
    color: white;
    border: none;
    border-radius: 20px;
  }
}
