@import '../../constants/colors';

.comment-button-color {
  background: $oculo-green !important;
}

.comment-button-shape {
  border-radius: 1.25rem !important;
}

text {
  margin-left: 8%;
}
