@import '../../constants/colors';

.input-with-icon {
  position: relative;
  flex: 1;

  .input {
    border: none;
    border-radius: 20px;
    font-size: 14px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .icon {
    position: absolute;
    padding: 0.375rem 0.75rem;
    font-size: 16px;
  }
}

.input-with-icon-default .input {
  color: $dark-gray-text;
  background-color: white;
  font-weight: 700;

  .input::placeholder {
    color: $dark-gray-text;
    font-weight: 700;
  }

  .icon {
    color: $dark-gray-text;
  }
}
