@import './constants/colors';

.App {
  font-weight: 400;
  font-family: Open Sans;
  font-style: normal;
  font-size: 14px;
}

.oculo-button button {
  border-radius: 20px !important;
  background: white;
  border: 1px solid $light-gray !important;
  color: #354052 !important;
}

.oculo-button button:hover,
.oculo-button button:focus {
  background-color: $light-gray;
}

.oculo-button .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: $light-gray !important;
}

.oculo-button .btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem $plus-light-gray;
}

.titles {
  width: 282px;
  height: 28px;
  left: 692px;
  top: 446px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height, or 117% */

  display: flex;
  align-items: center;
  letter-spacing: 0.325em;
  text-transform: uppercase;

  color: black;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

.content-page {
  padding: 35px;
}

.dropdown-menu {
  padding: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  box-shadow: 0px 2px 3px #dfe2e5;
}

.dropdown-item {
  min-height: 45px;
  display: flex;
  align-items: center;
  border-top: solid 1px #dfe2e5;
}

.dropdown-item:first-child {
  border-top: none;
}

@media (max-width: 767.98px) {
  .content-page {
    padding: 25px 0;
  }
}
