@import '../../constants/colors';
.table-control-buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap-reverse;
}

.table-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.content-page {
  .spinner-div {
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: 100%;
    z-index: 4;
    backdrop-filter: blur(3px);

    .spinner {
      bottom: 10%;
      left: 1%;
      width: 50px;
      height: 50px;
      position: absolute;
      color: $oculo_blue;
    }
  }
}
