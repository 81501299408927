@import '../../constants/colors';

/*CSS for table*/
.table-transactions {
  -webkit-box-shadow: 1px 3px 8px -3px rgba(0, 0, 0, 0.54);
  -moz-box-shadow: 1px 3px 8px -3px rgba(0, 0, 0, 0.54);
  box-shadow: 1px 3px 8px -3px rgba(0, 0, 0, 0.54);
  margin-bottom: 1rem;

  .table {
    width: 100%;
    background-color: white;
    margin-bottom: 0rem;
  }

  .table td,
  .table th {
    padding: 1rem;
    vertical-align: middle;
  }

  .table th {
    font-weight: 400 !important;
  }

  .table thead {
    color: white;
    background-color: $oculo-blue;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }

  .table thead th {
    border-bottom: 1px solid #dee2e6;
  }

  .table tbody,
  .table button {
    font-size: 12px;
  }

  .table button {
    text-transform: uppercase;
    min-width: 100px;
  }

  .table text {
    margin: 0px;
  }

  .table td {
    border-top: 0px;
  }

  .center-th,
  .select-checkbox {
    text-align: center;
  }

  #pid,
  .trans-number {
    font-weight: bold;
  }

  #type-transaction {
    text-transform: uppercase;
  }

  #currency {
    text-transform: uppercase;
  }

  #currency span {
    padding: 10% 15% 10% 15%;
    background-color: $light-gray;
    border-radius: 3px;
  }

  .debit {
    color: #981093;
  }

  .credit {
    color: #123bcd;
  }

  .deposit {
    color: #8227f5;
  }

  .withdrawal {
    color: #413055;
  }

  .amount-balance,
  .currency,
  #analysis,
  #bank-partner,
  #view-button,
  #status,
  #asignee {
    text-align: center;
  }

  #status {
    text-transform: uppercase;
  }

  .circle-icon-button {
    color: $icon-gray;
    border-radius: 20px;
    border: solid 2px $icon-gray;
    padding: 4px;
    font-size: 14px;
    line-height: 0;
    width: 26px !important;
    min-width: 26px !important;
    height: 26px;

    &.emphasize {
      color: $dark-gray-text;
    }
  }

  .oculo-button {
    [content*='erificado'] button,
    a[content*='erificado'],
    [content*='erto'] button,
    a[content*='erto'] {
      color: $oculo-green !important;
    }

    [content*='raude'] button,
    a[content*='raude'],
    [content*='rrado'] button,
    a[content*='rrado'] {
      color: $oculo-red !important;
    }

    [content*='nomalía'] button,
    a[content*='nomalía'] {
      color: $oculo-orange !important;
    }
  }
  .dropdown-menu.show {
    position: fixed !important;
  }
}
