@import '../../constants/colors';

.comment-box {
  left: 40.06%;
  right: 29.51%;
  top: 64.9%;
  bottom: 8.61%;

  background: $light-beige;
  box-shadow: inset 3px 3px 12px 10px $light-gray;
  border: 2px solid $oculo-blue !important;
  border-radius: 10px !important;

  resize: none;
}

.comment-title {
  width: 189px;
  height: 28px;
  left: 657px;
  top: 345px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;

  display: flex;
  align-items: center;
  letter-spacing: 0.325em;

  color: black;

  margin-top: 10px;
  margin-bottom: 20px;
}
