@import '../../constants/colors';

.profile-background {
  background: url('../../assets/profileBackground.svg') no-repeat center center;
  background-size: cover;
  height: 162px;
}

.profile-first-row {
  height: 269px;
  background-color: white;
  padding-bottom: 45px;
  align-items: flex-end;
  justify-content: center;
  position: relative;
}

.profile-first-row .first-row-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  padding: 0 40px;
}

.profile-first-row .profile-picture {
  background-color: $oculo-red;
  color: white;
  width: 282px;
  height: 282px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 144px;
  font-weight: 600;
  position: absolute;
  bottom: 122px;
}

.profile-panel-row {
  background-color: white;
  margin-top: 1px;
  min-height: 112px;
}

.profile-panel-row .data-row {
  padding: 10px;
  font-size: 14px;
  font-weight: 400;

  .data-name {
    font-weight: 700;
  }
}
.password-edit-button {
  font-size: 14px;
  font-weight: 700;
  padding: 0;
}

.change-password-form {
  background-color: $plus-light-gray;
  border-radius: 20px;
  max-width: 530px;
  margin: 25px 10px;
  padding: 35px 20px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-group {
    max-width: 328px;
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .profile-first-row .first-row-data {
    padding: 0 20px;
  }
}
