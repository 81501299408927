@import '../../constants/colors';

.pagination {
  margin-left: auto;

  li {
    list-style-type: none;
  }

  a {
    border: none;
    color: #6e7071;
  }

  .page-link:focus,
  .page-link:hover,
  .page-link:first-child,
  .page-link:last-child {
    border-radius: 20px;
  }

  .page-link {
    background-color: transparent;
  }

  .page-link:focus {
    box-shadow: none;
  }

  .page-link:hover::not(.active) {
    color: $oculo-red;
  }

  .active span {
    background-color: $oculo-red !important;
    border-color: $oculo-red !important;
    border-radius: 20px;
  }

  .navIcons a {
    color: black !important;
  }
}
