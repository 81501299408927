$oculo-red: #ff1b36;
$oculo-blue: #0a2239;
$oculo-green: #28a745;
$oculo-submenu-green: #00916e;
$oculo-orange: #e99000;
$dark-gray-text: #4f4949;
$light-gray: #c5d0de;
$plus-light-gray: #efefef;
$light-beige: #f8f1f1;
$icon-gray: #dadada;
