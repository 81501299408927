.main-page {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .page-content {
    position: relative;
    background-color: #eff3f6;
    display: flex;
    flex: 1;
    overflow: hidden;
  }

  .page-view {
    overflow: auto;
    flex: 1;
  }
}
