@import '../../constants/colors';
$search-width: 360px;

.search {
  max-width: $search-width;
  flex: 1;
}

.search-bar {
  max-width: $search-width;
  position: relative;

  .search-bar-icon {
    position: absolute;
    padding: 0.375rem 0.75rem;
    color: white;
  }

  .search-bar-input {
    border: none;
    border-radius: 20px;
    color: $light-beige;
    background-color: $oculo_blue;
    font-size: 14px;
    font-weight: 400;
    padding-left: 40px;
    padding-right: 40px;

    ::placeholder {
      color: $light-beige;
    }
  }

  .search-advanced-icon {
    color: $light-beige;
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search-advanced-icon:hover {
    cursor: pointer;
    background-color: $oculo-red;
    border-radius: 20px;
  }

  .search-advanced-icon:focus {
    outline: none;
  }
}

.advanced-search {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  margin-top: 0.3%;
  width: $search-width;
  z-index: 5;

  .form-group,
  p {
    margin: 5%;
  }

  .advanced-search-button {
    background-color: $oculo_blue;
    color: white;
    border: none;
    border-radius: 20px;
    margin-bottom: 4%;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 767.98px) {
    background-color: white;
    position: fixed;
    top: 55px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }
}
