@import '../../constants/colors';

.sidebar {
  flex-direction: column;
  background-color: $oculo-blue;
  width: 260px;
  overflow-y: auto;
  flex-wrap: nowrap;

  .nav-link {
    display: flex;
    align-items: center;
    gap: 12px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.325rem;
    color: white;
    font-family: Roboto;
    padding: 27px 35px;
  }

  .submenu-nav-link {
    background-color: $oculo-submenu-green;
    padding-left: 64px;
  }

  .nav-link.active {
    background-color: $oculo-red;
  }

  .nav-link-icon {
    font-size: 18px;
    color: $plus-light-gray;
  }
}

.sidebar-wrapper {
  display: flex;
  box-shadow: 1px 3px 7px 3px rgba(0, 0, 0, 0.54);
}

.sidebar-wrapper .close-button {
  display: none;
}

@media (max-width: 767.98px) {
  .sidebar-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 100;
    background-color: rgba(237, 237, 237, 0.84);
    display: none;
  }

  .sidebar-wrapper.open {
    display: flex;
  }

  .sidebar-wrapper .close-button {
    display: block;
    position: absolute;
    top: 30px;
    right: 30px;
    color: rgba(0, 0, 0, 0.6) !important;
    background: transparent !important;
    border: none !important;
    font-size: 20px;
    padding: 0;
  }
}
