@import '../../constants/colors';

.set-as-button button {
  background: $light-gray !important;
}

.filter-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-left: auto;
  flex: 1;

  @media (max-width: 767.98px) {
    flex-wrap: wrap;
  }
}
