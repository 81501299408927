.page-login {
  background: url('../../assets/background.svg') no-repeat center center fixed;
  background-size: cover;

  .height-content {
    height: calc(100vh - 20%);
  }

  .rounded-card {
    border-radius: 1rem !important;
    max-width: 500px;
  }

  .logo-login {
    margin: 2% 0% 2% 0%;
  }

  .bg-light.row {
    margin-right: -15px;
    margin-left: -15px;
  }

  .login-title {
    text-align: center;

    .titles {
      font-size: 31px;
      display: contents;
    }
  }

  @media (max-width: 767.98px) {
    .titles {
      font-size: 25px !important;
      letter-spacing: 0.225em;
    }
    .login-title {
      word-break: break-all;
    }
    .subtitle {
      font-size: 20px;
    }
  }
}
